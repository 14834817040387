import React from 'react';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import TextWidget from '../Widget/TextWidget';
const menuDataOne = [
   { title: 'About Us', href: '/about' },
   { title: 'Projects', href: '/projects' },
  { title: 'Blog', href: '/blog' },
  { title: 'Contact Us', href: '/contact' },
 ];
const menuDataTwo = [

 ];

export default function Footer() {
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: 'url(/images/footer_bg_1.svg)' }}
      >
        <div
          className="cs_footer_brand"

        >
          <img
            src="/images/logo_icon2.svg"
            alt="Logo Icon"
            className="cs_footer_brand_icon"
          />
         </div>
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <TextWidget text="Creation and creativity, these are our dreams in this company, through them we hope to reach perfection" />
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title="Be Our Subscribers"
                  subTitle="Stay updated with the latest insights and trends from our digital development experts"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom cs_accent_bg">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <SocialWidget />
            <div className="cs_copyright">
              Copyright © 2024 Screen Craft Solutions. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
