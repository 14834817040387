import React, {useEffect, useState} from 'react';
import BannerSectionStyle9 from '../Section/BannerSection/BannerSectionStyle9';
import Section from '../Section';
import BlogSectionStyle2 from '../Section/BlogSection/BlogSectionStyle2';
import Breadcrumb from '../Breadcrumb';
import { pageTitle } from '../../helpers/PageTitle';
import Banner from "../Section/BannerSection";


export default function Blog() {
  pageTitle('Blog');
  const [blogs, setblogs] = useState([]);
  useEffect(() => {
    fetch('https://screencraftsolutions.com/api/blogs.php')
        .then(response => response.json())
        .then(data => setblogs(data))
        .catch(error => console.error('Error fetching data:', error));
  }, []);



  return (
    <>
      <Section topMd={170} bottomMd={96} bottomLg={70}>
        <Breadcrumb title="News & Events" />
      </Section>
      <Section bottomMd={200} bottomLg={150} bottomXl={110}>
        <BlogSectionStyle2 data={blogs} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle9
            title= 'Bring Your Digital   <br />Vision to Life!'
            subTitle="Get in touch with our expert developers at Screen Craft Solutions  <br />today to bring your digital projects to life!"
            imgUrl="images/home_1/futuring.jpg"
        />
      </Section>
    </>
  );
}
