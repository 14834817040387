import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Projects from './components/Pages/Projects';
import Blog from './components/Pages/Blog';
import DepartmentDetails from './components/Pages/DepartmentDetails';
import BlogDetails from './components/Pages/BlogDetails';
import Contact from './components/Pages/Contact';
import { useEffect } from 'react';
import ErrorPage from './components/Pages/ErrorPage';
import Development from "./components/Pages/Development";


function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="projects" element={<Projects />} />
         <Route path="blog" element={<Blog />} />
         <Route path="development" element={<Development />} />
        <Route path="blog/:blogId" element={<BlogDetails />} />

           <Route path="contact" element={<Contact />} />
      </Route>


      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
