import React, {useEffect, useState} from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import TeamSectionStyle2 from '../Section/TeamSection/TeamSectionStyle2';
import Section from '../Section';
import { pageTitle } from '../../helpers/PageTitle';
import AwardSectionStyle2 from "../Section/AwardSection/AwardSectionStyle2";
const awardData = [
  {
    title: 'Swift ',
    iconUrl: 'images/brands/Apple.svg',
  },
  {
    title: 'Android',
    iconUrl: 'images/brands/android.png',
  },
  {
    title: 'TIZEN TV',
    iconUrl: 'images/brands/Tizen_Logo.png',
  },
  {
    title: 'LG WebOS',
    iconUrl: 'images/brands/lgwebos.png',
  },
  {
    title: 'Angular JS',
    iconUrl: 'images/brands/angular.png',
  },
  {
    title: 'React JS',
    iconUrl: 'images/brands/react.png',
  },
  {
    title: 'Vue JS',
    iconUrl: 'images/brands/vuejs.png',
  },

  {
    title: 'NodeJS',
    iconUrl: 'images/brands/nodejs.webp',
  },

  {
    title: 'Svelte',
    iconUrl: 'images/brands/svelete.png',
  },

  {
    title: 'Laravel',
    iconUrl: 'images/brands/Laravel.png',
  },


  {
    title: 'Mysql / Mango DB',
    iconUrl: 'images/brands/database.png',
  },

  {
    title: 'Ubuntu Server',
    iconUrl: 'images/brands/ubuntu.png',
  },

];

export default function Projects() {
  pageTitle('Projects');

  const [projects, setprojects] = useState([]);
  useEffect(() => {
    fetch('https://screencraftsolutions.com/api/projects.php')
        .then(response => response.json())
        .then(data => setprojects(data))
        .catch(error => console.error('Error fetching data:', error));
  }, []);
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/doctors/banner_bg.svg"
        imgUrl="/images/projects/Hero_Graphic_min_53b400e3ca.svg"
        title="Take a look some of our recent  case works "
        subTitle="A showcase of our featured works with years of professional expertise"
      />
      <Section topMd={65} bottomMd={200} bottomLg={150} bottomXl={110}>
        {
          projects && projects.length>0 &&
          <TeamSectionStyle2 data={projects} />

        }
      </Section>


      <Section
          topMd={50}
          topLg={50}
          topXl={50}
          bottomMd={50}
          bottomLg={50}
          bottomXl={50}
      >
        <AwardSectionStyle2
            sectionTitle="Working great with  <br />top platforms "
            sectionTitleUp=""
            sectionSubTitle=""
            data={awardData}
        />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/projects/lifeprojects.webp"
          title="Bring Your Digital   Vision to Life !"
          subTitle="Get in touch with our expert developers at Screen Craft Solutions today to bring your digital projects to life!"
        />
      </Section>
    </>
  );
}
