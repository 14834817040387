import { Icon } from '@iconify/react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Post({
  id,
  title,
                               thumb,
  date,
   href,
   variant,
}) {
  return (
    <div className={`cs_post cs_style_1 ${variant}`}>
      <Link to={href} className="cs_post_thumb cs_view_mouse">
        <img src={`https://screencraftsolutions.com/img/blog/${thumb}`}  alt={title} style={{padding:30}} />
      </Link>
      <div className="cs_post_info">
        <div>
          <div className="cs_post_meta">
            <div className="cs_posted_by">{date}</div>

          </div>
          <h2 className="cs_post_title cs_semibold cs_fs_32">
            <Link to={href}>{title}</Link>
          </h2>
        </div>

          <div className="cs_heading_color cs_medium">
            <Link to={`/blog/${id}`} className="cs_post_btn">
              Learn More
            </Link>
          </div>

      </div>
    </div>
  );
}
