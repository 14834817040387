import React, {useEffect, useState} from 'react';
import Hero from '../Hero';

import AboutSection from '../Section/AboutSection';
import BrandsSection from '../Section/BrandsSection';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';
import TestimonialSection from '../Section/TestimonialSection';
import BlogSection from '../Section/BlogSection';
import AppointmentSection from '../Section/AppointmentSection';
import FaqSection from '../Section/FaqSection';
import AwardSection from '../Section/AwardSection';
import DepartmentSection from '../Section/DepartmentSection';
import { pageTitle } from '../../helpers/PageTitle';
 import TeamSectionStyle3 from "../Section/TeamSection/TeamSectionStyle3";
import AwardSectionStyle2 from "../Section/AwardSection/AwardSectionStyle2";
 const featureListData = [
  {
    iconSrc: '/images/home_1/compassion.svg',
    title: 'Innovation',
    subTitle:
      'Embracing new ideas and pushing boundaries to drive progress.',
  },
  {
    iconSrc: '/images/home_1/excellence.svg',
    title: 'Excellence',
    subTitle:
      'Striving for the highest quality and continuous improvement',
  },
  {
    iconSrc: '/images/home_1/integrity.svg',
    title: 'Integrity',
    subTitle: `Upholding honesty, transparency, and ethical practices in all we do`,
  },
  {
    iconSrc: '/images/home_1/respect.svg',
    title: 'Services',
    subTitle:
      'Prioritizing client needs and delivering exceptional value.',
  },
  {
    iconSrc: '/images/home_1/teamwork.svg',
    title: 'Collaboration',
    subTitle:
      'Working together to achieve common goals and foster teamwork.',
  },
];

const awardData = [
  {
    title: 'Swift ',
    iconUrl: 'images/brands/Apple.svg',
  },
  {
    title: 'Android',
    iconUrl: 'images/brands/android.png',
  },
  {
    title: 'TIZEN TV',
    iconUrl: 'images/brands/Tizen_Logo.png',
  },
  {
    title: 'LG WebOS',
    iconUrl: 'images/brands/lgwebos.png',
  },
  {
    title: 'Angular JS',
    iconUrl: 'images/brands/angular.png',
  },
  {
    title: 'React JS',
    iconUrl: 'images/brands/react.png',
  },
  {
    title: 'Vue JS',
    iconUrl: 'images/brands/vuejs.png',
  },

  {
    title: 'NodeJS',
    iconUrl: 'images/brands/nodejs.webp',
  },

  {
    title: 'Svelte',
    iconUrl: 'images/brands/svelete.png',
  },

  {
    title: 'Laravel',
    iconUrl: 'images/brands/Laravel.png',
  },


  {
    title: 'Mysql / Mango DB',
    iconUrl: 'images/brands/database.png',
  },

  {
    title: 'Ubuntu Server',
    iconUrl: 'images/brands/ubuntu.png',
  },

];
const faqData = [
  {
    title: 'What services does Screen Craft Solutions offer?',
    content:
      'Screen Craft Solutions offers a wide range of development services, including web development, mobile app development, full-stack development, custom software solutions, UI/UX design, and more. We specialize in both front-end and back-end development, ensuring a seamless digital experience for your projects.',
  },
  {
    title: 'What is full-stack development?',
    content:
      'Full-stack development involves working on both the front-end (client side) and back-end (server side) of a web or mobile application. Our developers at Screen Craft Solutions are proficient in handling everything from the user interface to the server, database, and application logic.',
  },
  {
    title: 'How long does it take to complete a development project?',
    content:
      'The timeline for a development project varies depending on the complexity and scope of the project. We work closely with our clients to establish realistic timelines and deliver quality work on schedule. For a more accurate estimate, please contact us with the details of your project.',
  },
  {
    title: 'Do you offer ongoing support and maintenance?',
    content:'Yes, Screen Craft Solutions provides ongoing support and maintenance for all projects we develop. We ensure that your digital solutions continue to run smoothly and stay up-to-date with the latest technologies.'
   },
  {
    title: 'How much does it cost to develop a website or mobile app?',
    content:'The cost of developing a website or mobile app depends on various factors, including the project\'s complexity, features, and timeline. We offer customized quotes based on your specific needs and budget. Contact us for a detailed consultation.'
   },
  {
    title: 'What technologies do you use for development?',
    content:"Our team at Screen Craft Solutions is skilled in a variety of technologies, including but not limited to React.js, Laravel, Node.js, AngularJS, Svelte.js, PHP, Android development, iOS development with Swift and Objective-C, Xcode,  and more. We select the best technologies to match your project’s requirements."
    },
  {
    title: 'Can you help with the design aspect of my project?',
    content:'Absolutely! Our agency offers comprehensive UI/UX design services to create visually appealing and user-friendly interfaces. We work closely with you to ensure that the design aligns with your brand and enhances the user experience.'

     },
  {
    title: 'Do you provide custom software solutions?',
    content:'Yes, we specialize in developing custom software solutions tailored to meet your specific business needs. Whether it\'s a web application, mobile app, or enterprise software, we create solutions that drive results.'
 
     },
];
  const departmentData = [
  {
    title: 'Graphic designer',
    iconUrl: 'images/home_1/services_01.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Web development',
    iconUrl: 'images/home_1/services_02.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Mobile application',
    iconUrl: 'images/home_1/services_03.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Architect database',
    iconUrl: 'images/home_1/services_04.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Project design',
    iconUrl: 'images/home_1/services_05.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Server configuration and maintenance',
    iconUrl: 'images/home_1/services_06.svg',
    href: '/departments/department-details',
  },
];

export default function Home() {

  const [projects, setprojects] = useState([]);
  useEffect(() => {
    fetch('https://screencraftsolutions.com/api/projects.php')
        .then(response => response.json())
        .then(data => setprojects(data))
        .catch(error => console.error('Error fetching data:', error));
  }, []);

  const [blogs, setblogs] = useState([]);
  useEffect(() => {
    fetch('https://screencraftsolutions.com/api/blogs-home.php')
        .then(response => response.json())
        .then(data => setblogs(data))
        .catch(error => console.error('Error fetching data:', error));
  }, []);
  pageTitle('Home');
  return (
    <>
      <Hero
        title="Creation and Creativity: Our Path to Perfection."
        subTitle="We started this job with ambitions and imaginations in mind by which we have believed we could create our own destiny filled with success in this innovative world."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.svg"

      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={100}
        bottomLg={100}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/home_1/abouts-img.svg"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="About Us"
          subTitle="Screen Craft Solutions"
          featureList={[
            {
              featureListTitle:
                'Screen Craft Solutions is a team of experienced developers and innovators',
              featureListSubTitle:
                'No matter how tiny the candle light is, it still manages to light up the darkness. We have a tendency to transform a little flame into an explosive energy. Our technique is to detect the smallest details and create through them an amazing project',
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>
      {/* Start Award Section */}
      <Section
          topMd={50}
          topLg={50}
          topXl={50}
          bottomMd={50}
          bottomLg={50}
          bottomXl={50}
      >
        <AwardSectionStyle2
            sectionTitle="Working great with  <br />top platforms "
            sectionTitleUp=""
            sectionSubTitle=""
            data={awardData}
        />
      </Section>
      {/* End Award Section */}

      <Section topMd={100} topLg={100} topXl={105}>
        <TeamSectionStyle3
            sectionTitle="Popular Projects"
            sectionTitleUp="Featured Works"
            data={projects}
        />
      </Section>
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/futuring.jpg"
          title="Bring Your Digital   Vision to Life!"
          subTitle="Get in touch with our expert developers at Screen Craft Solutions today to bring your digital projects to life!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogs}
        />
      </Section>


      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}

    </>
  );
}
